const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.demo.forwoodsafety.com',
    APP_CLIENT_ID: '3tn4nb32nahtsnacpjtceo6uqa',
    APIGATEWAY: 'https://4v2nbh59oa.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://iq8ywtcv5b.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'actions-container-1.7.0',
    HOSTNAME: 'https://actions.demo.forwoodsafety.com',
    ACTIONS_ACCESS_ROLE: 'ActionsAccess',
    ACTION_PLAN_LIST_URL: 'https://iframe.demo.forwoodsafety.com/ccps_action_plan_responsible_report',
    ACTION_PLAN_DASHBOARD_URL: 'https://iframe.demo.forwoodsafety.com/action-plan-dashboard',
    ACTION_PLAN_BULK_EDIT_URL: 'https://iframe.demo.forwoodsafety.com/ccps-action-plan-bulk-edit',
    IFRAME_ORIGIN_URL: 'https://iframe.demo.forwoodsafety.com',
    CRM_HOMEPAGE_URL: 'https://iframe.demo.forwoodsafety.com/?v4_app=actions'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
